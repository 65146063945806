<template>
  <div>
    <el-dialog class="dialog1" width="540px" top="0" :title="`给${name}同学的点评`" :visible.sync="visible" :before-close="handleClose" v-if="dialogType === 1">
      <template #title>
        点评 <span style="color: #309AF2;margin: 0 10px;">{{ name }}</span> 的考试
      </template>
      <div class="content">
        <div class="list" v-for="item in list" :key="item.id">
          <div class="time">{{item.createAtStr}}</div>
          <div class="text" v-if="item.type === 0">{{item.content}}</div>
          <div v-else>
            <AudioBtn :url="item.content"></AudioBtn>
          </div>
        </div>
      </div>
      <div class="footer">
        <el-input class="input" type="textarea" placeholder="请输入点评" :rows="4" v-model="content"></el-input>
        <div class="footer-operation">
          <div class="review-template">
            <el-popover ref="popoverRef" width="484" placement="top-end" popper-class="review-template-popover" trigger="click">
              <div class="template-popover-title">
                <div class="template-popover-title-value">选择模板</div>
                <img :src="require('@/assets/close.png')" alt="" @click="closePopover">
              </div>
              <div class="review-template-list">
                <div v-for="(item, index) in templateList" :key="index" class="template-item" @click="selectTemplate(item)">
                  {{ item }}
                </div>
              </div>
              <div slot="reference" class="popover-reference">
                点评模板
                <img :src="require('@/assets/z-4.png')" alt="">
              </div>
            </el-popover>
          </div>
          <div class="review-btn" :class="{ disabled }" @click="addReview">发布点评</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog class="dialog2" width="600px" title="查看未完成名单" :visible.sync="visible" :before-close="handleClose" v-if="dialogType === 2">
      <div class="title">
        <span>当前还有</span><span class="num">{{num}}</span><span>人未完成</span>
      </div>
      <textarea contenteditable="true" id="copyText" class="dialog2-content" v-model="unfinishContent" readonly></textarea>
      <div class="foot">
        <el-button class="copy-btn btn-primary mr-28" type="primary" @click="unfinishContentCopy">复制未完成名单</el-button>
        <el-button class="copy-btn empty" @click="batchUrge(unfinishIds)">一键催交</el-button>
      </div>
    </el-dialog>

    <el-dialog class="dialog3" width="600px" :title="`批量${dialogName}`" :visible.sync="visible" :before-close="handleClose" v-if="dialogType === 3">
      <div class="user">
        <ul class="row title">
          <li class="col name">姓名</li>
          <li class="col">完成度</li>
          <li class="col">得分</li>
          <li class="col">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          </li>
        </ul>
        <div class="scroll-content">
          <el-checkbox-group v-model="checkList" @change="handleChange">
            <ul class="row" v-for="item in list" :key="item.id">
              <li class="col">
                <div class="name">{{item.realName}}</div>
                <div class="label" v-if="dialogFlag === 3 && item.homeworkState === '1'">已{{dialogName}}</div>
              </li>
              <li class="col">{{item.activeRate}}</li>
              <li :class="['col', item.scoreRate > 85 ? 'col-1DC060' : item.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30']">{{item.scoreRate}}分</li>
              <li class="col">
                <el-checkbox :label="item.id">{{ }}</el-checkbox>
              </li>
            </ul>
          </el-checkbox-group>
        </div>
      </div>
      <div class="foot">
        <el-checkbox @change="handleFilter">过滤已{{dialogName}}的学生</el-checkbox>
        <el-button class="urge-btn" type="primary" :disabled="urgeDisabled" @click="batchHandle">一键{{dialogName}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AudioBtn from '@/components/AudioBtn.vue'
import { getDictInfo } from '@/api/common.js'

export default {
  name: 'Dialog',
  props: ['dialogType', 'dialogFlag', 'visible', 'id', 'name', 'list', 'num', 'unfinishContent', 'unfinishIds'],
  components: {
    AudioBtn
  },
  data () {
    return {
      content: '',
      checkAll: false,
      isIndeterminate: false,
      checkList: [],
      templateList: []
    }
  },
  computed: {
    disabled: function () {
      return this.content.length === 0
    },
    urgeDisabled: function () {
      return this.checkList.length === 0
    },
    dialogName: function () {
      return this.dialogFlag === 1 ? '催交' : this.dialogFlag === 2 ? '点评' : '打回'
    }
  },
  watch: {
    visible(val) {
      if (val && this.dialogType === 1) {
        this.fetchDictInfo()
      }
    }
  },
  methods: {
    batchHandle () {
      if (this.checkList.length === 0) return
      const { dialogFlag } = this
      if (dialogFlag === 1) {
        this.batchUrge()
      } else if (dialogFlag === 3) {
        this.batchRedo()
      }
    },
    batchRedo () {
      const { checkList } = this
      const userIds = checkList.join(',')
      this.$emit('batchRedo', userIds)
    },
    batchUrge (unfinishIds) {
      const { checkList } = this
      const userIds = unfinishIds || checkList.join(',')
      this.$emit('batchUrge', userIds)
    },
    handleFilter (val) {
      if (val) {
      }
    },
    handleChange (val) {
      const len = val.length
      this.checkAll = len === this.list.length
      this.isIndeterminate = len > 0 && len < this.list.length
    },
    handleCheckAllChange (val) {
      if (val) {
        const arr = []
        const { list } = this
        for (let i = 0, len = list.length; i < len; i += 1) {
          arr.push(list[i].id)
        }
        this.checkList = arr
      } else {
        this.checkList = []
      }
      this.isIndeterminate = false
    },
    handleClose () {
      this.checkAll = false
      this.checkList = []
      this.$emit('handleClose')
    },
    addReview () {
      if (this.content.length === 0) return
      this.$emit('addReview', this.id, this.content)
      this.content = ''
    },
    unfinishContentCopy () {
      const inputElement = document.getElementById('copyText')
      inputElement.select()
      document.execCommand('Copy')
      this.$message({
        message: '复制成功',
        type: 'success'
      })
    },
    // 获取点评模板
    fetchDictInfo() {
      const params = {
        param: 'review_template_config',
        name: 'xiyou'
      }
      getDictInfo(params).then(res => {
        const { data, state } = res
        if (state === '11') {
          this.templateList = data.value ? data.value.split('#') : []
        }
      })
    },
    closePopover() {
      this.$refs.popoverRef.doClose()
    },
    // 选择点评模板
    selectTemplate(val) {
      this.content = val
      this.$refs.popoverRef.doClose()
    }
  }
}
</script>

<style lang="scss">
  .dialog1,
  .dialog2,
  .dialog3{
    .el-dialog{
      border-radius: 16px;
    }
    .el-dialog__header {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      .el-dialog__headerbtn{
        width: 50px;
        height: 50px;
        top: 14px;
        right: 14px;
        font-size: 22px;
        border-radius: 25px;
        //color: black;
        .el-icon-close{
          color: black;
        }
        &:hover{
          background-color: #F6F6F6;
          .el-icon-close{
            color: black;
          }
        }
      }
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  .dialog1{
    display: flex;
    align-items: center;
    justify-content: center;
    .el-dialog {
      margin: 0 auto;
      max-height: calc(100vh - 80px);
      .el-dialog__header{
        padding: 25px 28px;
        line-height: 28px;
        border-bottom: 1px solid #E5E5E5;
      }
    }
  }

  .review-template-popover{
    padding: 0;
    border-radius: 16px;
    .template-popover-title{
      padding: 28px;
      height: 78px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #101010;
      font-weight: bold;
      border-bottom: 1px solid #E5E5E5;
      .template-popover-title-value{
        height: 28px;
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        line-height: 28px;
      }
      img{
        width: 20px;
        height: 20px;
      }
    }
    .review-template-list{
      max-height: 400px;
      padding: 20px 28px;
      overflow-y: auto;
      .template-item{
        cursor: pointer;
        //width: 428px;
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        line-height: 24px;
        text-align: left;
        &+.template-item{
          margin-top: 20px;
        }
        &:hover{
          color: #309AF2;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .content {
    background-color: #FFFFFF;
    overflow: auto;
    padding: 20px 28px 0;
    max-height: calc(100vh - 290px - 80px);
    min-height: 200px;
    .time {
      margin-bottom: 6px;
      @include font(12px, #999);
    }
    .text {
      background-color: #F6F6F6;
      padding: 14px;
      border-radius: 8px;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
    }
  }
  .footer {
    background-color: #FFFFFF;
    padding: 14px 28px 28px;
    border-top: 1px solid #E5E5E5;
    border-radius: 0 0 16px 16px;
    ::v-deep.el-textarea{
      .el-textarea__inner{
        background: #F6F6F6;
        color: #333333;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        height: 110px;
        font-size: 16px;
        &::-webkit-input-placeholder{
          font-weight: 400;
          font-size: 16px;
          color: #999999;
          line-height: 22px;
          text-align: left;
        }
      }
    }
    .footer-operation{
      margin-top: 14px;
      height: 40px;
      position: relative;
      .review-btn {
        margin: 0 auto;
        width: 160px;
        height: 40px;
        background: linear-gradient( 90deg, #6DBBFC 0%, #309AF2 100%);
        border-radius: 4px;
        font-weight: 400;
        font-size: 15px;
        line-height: 40px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
        &:hover{
          background: linear-gradient( 90deg, #309AF2 0%, #309AF2 100%);
        }
        &.disabled{
          cursor: not-allowed;
          background: linear-gradient( 90deg, #C0E0FB 0%, #C0E0FB 100%);
          &:hover{
            background: linear-gradient( 90deg, #C0E0FB 0%, #C0E0FB 100%);
          }
        }
      }

      .review-template{
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #309af2;
        text-align: right;
        &>span{
          cursor: pointer;
        }
        .popover-reference{
          img{
            width: 11px;
            height: 6px;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .dialog2 {
    color: #333;
    .title {
      font-size: 15px;
      text-align: center;
      margin: 32px 0;
      .num {
        font-size: 20px;
        color: #309AF2;
        margin: 0 10px;
      }
    }
    .dialog2-content {
      background-color: #F6F6F6;
      width: 544px;
      height: auto;
      min-height: 30vh;
      max-height: 50vh;
      border: none;
      border-radius: 16px;
      padding: 20px;
      margin: 0 28px 28px;
      font-size: 17px;
      line-height: 24px;
      outline: 0 none;
      resize: none;
    }
    .foot {
      text-align: center;
      padding-bottom: 20px;
      .copy-btn {
        width: 150px;
        height: 40px;
        font-size: 15px;
        &.btn-primary{
          background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
          &:hover {
            background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
          }
        }
        &.empty{
          color: #309AF2;
          &:hover{
            background: #F6F6F6;
          }
        }
      }
    }
  }
  .dialog3 {
    .user {
      font-size: 15px;
      color: #333;
      text-align: center;
      padding: 0 28px 10px;
    }
    .scroll-content {
      max-height: 50vh;
      overflow: auto;
    }
    .row {
      @include flex;
      border-bottom: 1px solid #F1F1F1;
      .col {
        flex-grow: 1;
        flex-shrink: 0;
        width: 25%;
        height: 50px;
        line-height: 50px;
        position: relative;
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .label {
          height: 23px;
          line-height: 23px;
          padding: 0 7px;
          border: 1px solid #309AF2;
          border-radius: 8px 8px 8px 0;
          @include font(12px, #309AF2, center);
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .title {
      color: #999999;
    }
    .foot {
      @include flex(space-between);
      padding: 10px 28px 28px;
      box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
      .urge-btn {
        width: 160px;
        height: 40px;
      }
    }
  }
  .mr-28{
    margin-right: 28px;
  }
</style>
